<template>
  <div style="background-color: #D8D6E8;">
    <div class="login-box">
      <div class="login-logo">
        <img src="../assets/img/lendela_logo.svg">
      </div>
      <Authenticator :hide-sign-up="true" :login-mechanisms="['email']" :form-fields="formFields">
        <template v-slot:sign-in-header>
          <h1
            class="amplify-heading"
            style="padding: var(--amplify-space-xxl) 0 0 0; font-weight: bold; font-size: var(--amplify-font-sizes-large); text-align: center;"
          >
            Sign in to your account
          </h1>
        </template>
      </Authenticator>
    </div>
    <div v-if="notRegistered" class="cleared-section-wrapper">
      {{ getTexts('notRegistered') }}
    </div>
  </div>
</template>

<script>
import { fetchAuthSession, fetchUserAttributes, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Authenticator } from '@aws-amplify/ui-vue';
import auth from '../mixins/apiServices/auth';
import content from '../mixins/apiServices/content';

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';

import { useAuthStore } from '../store/auth';
import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';
import { useNuxtStore } from '../store/nuxt';

Amplify.configure(awsconfig);

export default {
  mixins: [auth, content],

  data () {
    return {
      notRegistered: false,
      formFields: {
        forgotPassword: {
          username: {
            label: "Email",
            placeholder: 'Enter your email',
          },
        },
      },
      authStore: useAuthStore(),
      appStore: useAppStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
      hubRemover: null,
    }
  },

  components: {
    Authenticator,
  },

  computed: {
    miscTexts () {
      return this.domainStore.miscTexts();
    },
  },

  async mounted () {
    if (this.authStore.token && this.authStore.user) {
      this.$router.push({ name: 'application-page' });
      return;
    }
    signOut({ global: true });
    this.hubRemover = Hub.listen('auth', async (data) => {
      const { payload: info } = data;
      if (info.event === 'signedIn') {
        this.handleLogin(true);
      }
    })
  },

  methods: {
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    async handleLogin () {
      let currentUser = null;
      let session = null;
      let cognitoToken = null;
      try {
        currentUser = await fetchUserAttributes();
        session = await fetchAuthSession();
        cognitoToken = (session?.tokens?.accessToken) ? session.tokens.accessToken.toString() : null;

      if (!currentUser['custom:locale']) {
          this.notRegistered = true;
          return;
        }
        
        this.nuxtStore.setLocale(currentUser['custom:locale'].toLowerCase());

        // get basic CMS text on login
        let { data: domains, responseCode: statusCode } = await this.getBasicDomains();
        this.domainStore.setDomains(domains);

        const { responseCode, data } = await this.getToken(cognitoToken);
        if (responseCode !== 200) {
          this.notRegistered = true;
          return;
        }

        if (!this.authStore) this.authStore = useAuthStore();
        this.authStore.setToken(data.token);
        this.hubRemover();

        // get full CMS text after login
        const { data: partnerDomains, responseCode: partnerStatusCode } = await this.getPartnerDomains();
        if (partnerStatusCode === 401) {
          this.appStore.clearAppStore();
          return location.reload();
        }

        this.domainStore.setDomains(partnerDomains);

        const { email, userId } = data;
        this.authStore.setUser({ email, userId });

        if (data.admin) {
          this.authStore.setAdmin(data.admin);
        }

        this.appStore.setListSettings(data.listSettings);
        if (data.listSettings && data.listSettings.language) {
          this.nuxtStore.setLang(data.listSettings.language);
        }
        if (window.localStorage.getItem('on-login-redirect')) {
          this.$router.push(window.localStorage.getItem('on-login-redirect'));
          window.localStorage.removeItem('on-login-rediirect');
          return;
        }

        const { data: { access_token: accessToken } } = await this.loginToPartnerAPI({ email, userId });

        this.authStore.setPartnerPortalToken(accessToken);
        this.$router.push({ name: 'application-page' });
        return;
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
